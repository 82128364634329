import React from 'react';
import {getLink, getRoutes} from "../../components/shared/routes";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().UseCases])} />);


// import React from 'react';
// import Layout from '../../components/shared/Layout';
// import SEO from '../../components/shared/SEO';
// import {useTranslation} from 'react-i18next';
// import css from './premium_brands.module.scss';
// import Footer from "../../components/shared/Footer/Footer";

// import {graphql, useStaticQuery} from 'gatsby';

// // import css from './premiumBrands.module.scss';
// import {getImagesFluid} from '../../components/componentsUtils';
// import {getId, getLink, getRoutes} from "../../components/shared/routes";
// import SlideTypeF from "../../components/shared/ImageSlider/SlideTypeF";
// import SlideTypeG from "../../components/shared/ImageSlider/SlideTypeG";
// import Movie from "../../movies/Inspirations/PremiumBrands/inspirations-premium-brands.mp4";
// import CoveredVideo from "../../components/shared/CoveredVideo/CoveredVideo";
// import PremiumBrandsIconBlack from "../../images/icons/premium_brands_b.svg";
// import GoToButton from "../../components/shared/GoToButton/GoToButton";
// import YouTubeIconGreen from "../../images/icons/play_video_green.svg";

// const query = graphql`
// {
//   fluid: allFile(filter: {sourceInstanceName: {eq: "images_PremiumBrands"}}) {
//     nodes {
//       childImageSharp {
//         fluid(maxWidth: 1090, quality: 70) {
//           ...GatsbyImageSharpFluid,
//           originalName
//         }
//       }
//     }
//   }
// }`;


// const PremiumBrandsPage = () => {
//   const { t } = useTranslation();
//   const imagesFluid = getImagesFluid(useStaticQuery(query));
//   const pageTitle = `${getRoutes().PremiumBrands.pageTitle} | ${getRoutes().Inspirations.pageTitle}`;

//   return (
//     <Layout>
//       <SEO title={pageTitle} description={'Premium products deserve pro images'}/>

//       <div className={css.wrapper}>

//         <div className={css.Events2}>
           
//         <div className={css.wrapper}>
//         <div className={css.slide1}>
//             <CoveredVideo
//                 videoSrcURL={Movie}
//                 placeholderImage={'Premium_Brands_1_placeholder.jpg'}
//                 imagesFluid={imagesFluid}
//                 icon={PremiumBrandsIconBlack}
//                 title="Premium Brands"
//                 text="Sharp details and bright colors ad more value."
//                 buttons={(
//                     <>
//                         <GoToButton
//                             sectionUrl={getLink([getRoutes().PremiumBrands, getRoutes().PremiumBrands.sections.getStarted])}
//                             text="Get started!"
//                         />
//                         <GoToButton
//                             externalPageUrl={'https://youtu.be/oCjR2Gr3BXc'}
//                             icon={YouTubeIconGreen}
//                             filled={false}
//                         />
//                     </>
//                 )}
//             />
//         </div>
//         <div className={css.slide2} id={getRoutes().PremiumBrands.sections.getStarted}>
//             <SlideTypeF
//                 title={<>Professional photo <br/>quality enhancement</>}
//                 text3={<p>Final optimisation result is just a visual improvement, <br/>without changing or modifying true context of the photo.</p>}
//                 fullImage={'Premium_Brands_2.jpg'}
//                 fullImageAlt="optimisation anonymisation auto cropping"
//                 phoneImage1={'Premium_Brands_mobile_1.jpg'}
//                 phoneImage1Alt="optimisation anonymisation auto cropping"
//                 phoneImage1Height={1100}
//                 imagesFluid={imagesFluid}
//                 imagePosition={'right'}
//             />
//         </div>
//         <div className={css.slide3}>
//             <SlideTypeF
//                 title={<>Professional photo <br/>quality editing</>}
//                 text3={<p>Editing selected functionality interferes into <br/>the photo parameters and the final result <br/>is a manipulated reconstruction.</p>}
//                 fullImage={'Premium_Brands_3.jpg'}
//                 fullImageAlt="inpainting upscaling artifacts removing"
//                 phoneImage1={'Premium_Brands_mobile_4.jpg'}
//                 phoneImage1Alt="inpainting upscaling artifacts removing"
//                 phoneImage1Height={1100}
//                 imagesFluid={imagesFluid}
//             />
//         </div>
//         <div className={css.slide4}>
//             <SlideTypeG
//                 title="Define your workflow requirements"
//                 fullImage={'Premium_Brands_4.jpg'}
//                 phoneImage={'Premium_Brands_mobile_7.jpg'}
//                 alt="Custom workflow"
//                 imagesFluid={imagesFluid}
//             />
//         </div>
//         </div>
//         </div>

//         <div className={css.Footer}>
//           <Footer/>
//         </div>

//       </div>
//     </Layout>
//   );
// };

// export default PremiumBrandsPage;

