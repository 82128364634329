// NOTE:
// 1. Every "main" route has to ends with "/". If not - navigation link won't be set as active on page refresh.
// 2. Check if there is something to change in gatsby-plugin-sitemap in gatsby-config.
const subpage10Route = '/inspirations/';
const subpage26Route = '/solutions/';

const routes = {
  home: {
    main: '/',
    sections: {
      news: 'news',
      software: 'software',
      getStarted: 'get_started',
      personal: 'personal',
      business: 'business',
      enterprise: 'enterprise',
      onlineSales: 'online_sales',
      sharing: 'sharing',
      restoration: 'restoration',
      webPublishing: 'web_publishing'
    }
  },
  news: {
    pageTitle: "News",
    main: '/news/', // NOTE: the same as siteMetadata.newsListUrl in gatsby-config.js
    sections: {}
  },

  UseCases: {
    pageTitle: "Use Cases",
    main: '/use_cases/', 
    sections: {}
  },
  LegalDocuments: {
    pageTitle: "Legal Documents",
    main: '/legal_documents/'
  },
  Contact: {
    pageTitle: "Contact",
    main: '/contact/'
  },


  // Inspirations: {
  //   pageTitle: 'Inspirations',
  //   main: subpage10Route,
  //   sections: {
  //     eCommerce: 'e-commerce',
  //     cars: 'cars',
  //     events: 'events',
  //     portals: 'portals'
  //   }
  // },
  // InspirationAbout: {
  //   pageTitle: "About",
  //   main: `${subpage10Route}about/`,
  // },
  // Automotive: {
  //   pageTitle: "Automotive",
  //   main: `${subpage10Route}cars/`,
  //   sections: {
  //     getStarted: 'get_started'
  //   }
  // },
  // PremiumBrands: {
  //   pageTitle: "Premium Brands",
  //   main: `${subpage10Route}premium_brands/`,
  //   sections: {
  //     getStarted: 'get_started'
  //   }
  // },
  // RealEstate: {
  //   pageTitle: "Real Estate",
  //   main: `${subpage10Route}real_estate/`,
  //   sections: {
  //     getStarted: 'get_started'
  //   }
  // },
  // WebPublishing: {
  //   pageTitle: "Web Publishing",
  //   main: `${subpage10Route}web_publishing/`,
  //   sections: {
  //     getStarted: 'get_started'
  //   }
  // },
  // Sharing: {
  //   pageTitle: "Sharing",
  //   main: `${subpage10Route}sharing/`,
  //   sections: {
  //     getStarted: 'get_started'
  //   }
  // },
  // DatingSites: {
  //   pageTitle: "Dating Sites",
  //   main: `${subpage10Route}dating_sites/`,
  //   sections: {
  //     getStarted: 'get_started'
  //   }
  // },
  // OnlineSales: {
  //   pageTitle: "Online Sales",
  //   main: `${subpage10Route}ecommerce/`,
  //   sections: {
  //     getStarted: 'get_started'
  //   }
  // },
  // AgedPhoto: {
  //   pageTitle: "Aged Photo",
  //   main: `${subpage10Route}restoration/`,
  //   sections: {
  //     getStarted: 'get_started'
  //   }
  // },

  Support: {
    pageTitle: "Support",
    main: '/support/',
  },

  UserGuide: {
    pageTitle: "User Guide",
    main: `/user_guide/`,
    sections: {
          contact: 'contact_us'
        }
    },
  Integrations: {
    pageTitle: "Integrations",
    main: `/integrations/`,
  },
  OptiwaiPlus: {
    pageTitle: "<app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak>",
    main: `${subpage26Route}about/`,
  },
  Explore: {
    pageTitle: "Explore",
    main: `${subpage26Route}explore/`,
  },
  // Integrations: {
  //   pageTitle: "Integrations",
  //   main: `${subpage26Route}integrations/`,
  // },
  Pricing: {
    pageTitle: "Pricing",
    main: `/pricing/`,
  },
  
  // UserGuide: {
  //   pageTitle: "User Guide",
  //   main: `${subpage26Route}user_guide/`,
  //   sections: {
  //     contact: 'contact_us'
  //   }
  // },
  Campaign: {
    pageTitle: "Campaign",
    main: `${subpage26Route}campaign/`,
  },
  Solutions: {
    pageTitle: "Solutions",
    main: `${subpage26Route}`,
  },
};

export const getRoutes = () => {
  return routes;
};

export const legalDocuments = {
  optiwai: {
    privacyPolicy: '/files/Optiwai_Privacy_Policy.pdf',
    termsAndConditions: '/files/Optiwai_Terms_And_Conditions.pdf',
    subusers: '/files/Optiwai_Subusers.pdf'
  },
  optiwaiplus: {
    privacyPolicy: '/files/OptiwaiPlus_Privacy_Policy.pdf',
    termsAndConditions: '/files/OptiwaiPlus_Terms_And_Conditions.pdf'
  }
};

export const optiPlusLoggingEnabled = false;

export const fastTrackPortalsEnabled = true;

// export const getLink = (route, sectionWithPage = false) => {
//   if (Array.isArray(route)) { // TODO temporary, refactor
//     return getLink2(route, sectionWithPage);
//   }

//   const indexRoute = routes.home.main;
//   if (typeof route !== 'string') {
//     return indexRoute;
//   }

//   const getPage = () => {
//     const page = routes[routesArray[0]];
//     if (!page) {
//       console.error('! route not found: ', routesArray[0]);
//     }
//     return page;
//   };

//   const getSection = page => page.sections[routesArray[1]];

//   const routesArray = route.split('.');
//   switch (routesArray.length) {
//     case 1: {
//       return getPage().main;
//     }
//     case 2: {
//       const page = getPage();
//       const section = getSection(page);
//       return sectionWithPage ? `${page.main}#${section}` : `#${section}`;
//     }
//     default:
//       return indexRoute;
//   }
// };

// TODO
export const getLink = (routesArray = [], sectionWithPage = false) => {
  const indexRoute = routes.home.main;

  const getPage = () => {
    const page = routesArray[0];
    if (!page) {
      console.error('! route not found: ', routesArray[0]);
    }
    return page;
  };

  switch (routesArray.length) {
    case 1: {
      return getPage().main;
    }
    case 2: {
      const page = getPage();
      const section = routesArray[1];
      return sectionWithPage ? `${page.main}#${section}` : `#${section}`;
    }
    default:
      return indexRoute;
  }
};

export const getId = route => {
  const defaultId = '';
  if (typeof route !== 'string') {
    return defaultId;
  }

  const getPage = () => routes[routesArray[0]];
  const getSection = page => page.sections[routesArray[1]];

  const routesArray = route.split('.');
  if (routesArray.length !== 2) {
    return defaultId;
  }
  const page = getPage();
  return getSection(page);
};